/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { Route, RouteComponentProps, Switch } from 'react-router-dom';
import PrivateRoute from '@views/components/private-route';
import PublicRoute from '@views/components/public-route';
import { Store } from 'redux';
import RoutesType from './interface';

type RouteCustomProperties = {
  store: Store;
};
type RouteWithSubRoutesProperties = RouteCustomProperties & RoutesType;
type RoutesComponent = typeof Route | typeof PrivateRoute | typeof PublicRoute;

const RouteWithSubRoutes: FC<RouteWithSubRoutesProperties> = ({
  component: Component,
  exact,
  path,
  routes,
  privated,
  published,
  store,
  ...rest
}) => {
  let RouterFinal: RoutesComponent = Route;
  if (privated) {
    RouterFinal = PrivateRoute;
  }
  if (published) {
    RouterFinal = PublicRoute;
  }

  if (routes) {
    return (
      <RouterFinal
        exact={exact}
        path={path}
        render={(routeProperties: RouteComponentProps) => (
          <Component {...routeProperties} {...rest}>
            <Switch>
              {routes.map((route) => (
                <RouteWithSubRoutes key={route.path} store={store} {...route} />
              ))}
            </Switch>
          </Component>
        )}
      />
    );
  }

  return (
    <RouterFinal
      exact={exact}
      path={path}
      render={(routeProperties: RouteComponentProps) => (
        <Component {...routeProperties} {...rest} />
      )}
    />
  );
};

const RouteConfig: FC<{ routes: RoutesType[]; store: Store }> = ({
  routes,
  store,
}) => (
  <Switch>
    {routes.map((route) => (
      <RouteWithSubRoutes key={route.path} store={store} {...route} />
    ))}
  </Switch>
);

export default RouteConfig;
