/* eslint-disable react/jsx-props-no-spreading */
import { FC } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { authIsLoggedIn } from '@modules/auth/selectors';

const PrivateRoute: FC<RouteProps> = ({ render, ...rest }) => {
  const isLoggedIn = useSelector(authIsLoggedIn);
  return (
    <Route
      {...rest}
      render={(otherProperties) =>
        isLoggedIn === true ? (
          render && render(otherProperties)
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: { from: otherProperties.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
