/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-import-module-exports */
/* eslint-disable unicorn/prefer-top-level-await */
/* eslint-disable unicorn/prefer-module */
import '@assets/styles/style.scss';
import 'antd/dist/reset.css';

import { render, hydrate } from 'react-dom';
import Loadable from 'react-loadable';
import App from './app';

const renderMethod = module.hot ? render : hydrate;

Loadable.preloadReady().then(() => {
  renderMethod(<App />, document.querySelector('#root'));
});
