/* eslint-disable import/no-named-as-default */
import produce, { Draft, Immutable } from 'immer';

import { retrieveRates, saveRates } from './actions';

export type RatesUserState = Immutable<{
  loading: boolean;
  rates: [];
  error: string | null;
}>;

const initialState: RatesUserState = {
  loading: false,
  rates: [],
  error: null,
};

export default produce((draft: Draft<RatesUserState>, { type, payload }) => {
  switch (type) {
    case retrieveRates.REQUEST:
      draft.loading = true;
      break;
    case retrieveRates.SUCCESS:
    case saveRates.SUCCESS:
      draft.error = null;
      draft.rates = payload;
      break;
    case retrieveRates.FAILURE:
      draft.error = payload;
      draft.loading = true;
      break;
    case retrieveRates.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);
