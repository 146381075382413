/* eslint-disable @typescript-eslint/ban-ts-comment */
import { all, call, spawn, StrictEffect } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';
import navWatch from '@modules/nav/sagas';
// @ts-ignore
import authWatch from '@modules/auth/sagas';
import rateUserhWatch from '@modules/rates-user/sagas';
import monthByMonthWatch from '@modules/month-by-month/sagas';

function* rootSaga(): Generator<StrictEffect> {
  yield all([
    // external
    call(routinePromiseWatcherSaga),
    spawn(navWatch),
    call(authWatch),
    call(rateUserhWatch),
    call(monthByMonthWatch),
  ]);
}

export default rootSaga;
