import { createSelector } from 'reselect';
import { RootState } from '@store/types';
import { RatesUserState } from './reducer';

export const getRatesUser = (state: RootState): RatesUserState =>
  state.ratesUser;

export const getRatesUserLoading = createSelector(
  getRatesUser,
  (ratesUser) => ratesUser.loading,
);

export const getRates = createSelector(
  getRatesUser,
  (ratesUser) => ratesUser.rates,
);
