import { createSelector } from 'reselect';
import { RootState } from '@store/types';
import { AuthState } from './reducer';

export const getAuth = (state: RootState): AuthState => state.auth;
export const getAuthToken = createSelector(getAuth, (auth) => auth.token);
export const authIsLoggedIn = createSelector(
  getAuth,
  (auth) => auth.isLoggedIn,
);
