/* eslint-disable redux-saga/yield-effects */
// @ts-nocheck
import {
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import { SDK } from '@utils/sdk';
import format from 'date-fns/format';
import { getFilters } from '@modules/nav/selectors';
import { retrieveMonthByMonth, syncMonthByMonth } from './actions';

export function* retrieveMonthByMonthSaga(): Generator<StrictEffect> {
  try {
    yield put(retrieveMonthByMonth.request());

    const filters = yield select(getFilters);
    const [year, month] = filters.date.split('-');
    const id = format(new Date(year, month - 1), 'yyyy-MM');
    const { data } = yield call(SDK.get, `time_entries/${id}`);
    yield put(retrieveMonthByMonth.success(data));
  } catch (error) {
    yield put(retrieveMonthByMonth.failure(error));
  } finally {
    yield put(retrieveMonthByMonth.fulfill());
  }
}

export function* syncMonthByMonthSaga(): Generator<StrictEffect> {
  try {
    yield put(syncMonthByMonth.request());

    const filters = yield select(getFilters);
    const [year, month] = filters.date.split('-');
    const id = format(new Date(year, month - 1), 'yyyy-MM');

    yield call(SDK.get, `time_entries`, {
      params: { date: id },
      // eslint-disable-next-line unicorn/numeric-separators-style
      timeout: 9999999,
    });
    yield call(retrieveMonthByMonthSaga);
  } catch (error) {
    yield put(syncMonthByMonth.failure(error));
  } finally {
    yield put(syncMonthByMonth.fulfill());
  }
}

export default function* monthByMonthWatch(): Generator<StrictEffect> {
  yield takeLatest(retrieveMonthByMonth.TRIGGER, retrieveMonthByMonthSaga);
  yield takeLatest(syncMonthByMonth.TRIGGER, syncMonthByMonthSaga);
}
