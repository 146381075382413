import { useEffect, FC } from 'react';
import Properties from 'types/react';
import { useSelector } from 'react-redux';

import { getAuthToken } from '@modules/auth/selectors';
import { SDK } from '@utils/sdk';

const SdkLoader: FC<Properties> = ({ children }) => {
  const token = useSelector(getAuthToken);

  useEffect(() => {
    (async () => {
      // eslint-disable-next-line dot-notation
      SDK.defaults.headers.common['Authorization'] = token;
    })();
  }, [token]);

  return children;
};

export default SdkLoader;
