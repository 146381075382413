import Loadable from 'react-loadable';
import ScreenLoading from 'views/ui/screen-loading';
import { retrieveRates } from '@modules/rates-user/actions';
import { retrieveMonthByMonth } from '@modules/month-by-month/actions';

const Index = Loadable({
  loader: () => import('views/screens/index'),
  // @ts-ignore
  loading: ScreenLoading,
});

const NotFound = Loadable({
  loader: () => import('views/screens/not-found'),
  // @ts-ignore
  loading: ScreenLoading,
});

const ContainerApp = Loadable({
  loader: () => import('views/containers/app'),
  // @ts-ignore
  loading: ScreenLoading,
});

const ScreenRatesUser = Loadable({
  loader: () => import('views/screens/rates-user'),
  // @ts-ignore
  loading: ScreenLoading,
});

const ScreenReportMonthByMonth = Loadable({
  loader: () => import('views/screens/report-month-by-month'),
  // @ts-ignore
  loading: ScreenLoading,
});

const routes = [
  {
    path: '/',
    component: Index,
    exact: true,
  },
  {
    path: '/app',
    component: ContainerApp,
    routes: [
      {
        path: '/app/rates-user',
        component: ScreenRatesUser,
        exact: true,
        defaultFilters: {
          date: new Date(),
        },
        actions: [retrieveRates],
      },
      {
        path: '/app/report-month-by-month',
        component: ScreenReportMonthByMonth,
        exact: true,
        defaultFilters: {
          date: new Date(),
        },
        actions: [retrieveMonthByMonth],
      },
    ],
  },
  {
    name: '404',
    path: '*',
    component: NotFound,
  },
];

export default routes;
export { routes };
