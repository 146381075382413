/* eslint-disable import/no-named-as-default */
import produce, { Draft, Immutable } from 'immer';

import { retrieveMonthByMonth, syncMonthByMonth } from './actions';

type Report = {
  id: string;
  projects: {};
  update_at: Date;
  created_at: Date;
};

export type MonthByMonthState = Immutable<{
  loading: boolean;
  report: Report | null;
  error: string | null;
}>;

const initialState: MonthByMonthState = {
  loading: false,
  report: null,
  error: null,
};

export default produce((draft: Draft<MonthByMonthState>, { type, payload }) => {
  switch (type) {
    case retrieveMonthByMonth.REQUEST:
    case syncMonthByMonth.REQUEST:
      draft.loading = true;
      break;
    case retrieveMonthByMonth.SUCCESS:
      draft.error = null;
      draft.report = payload;
      break;
    case retrieveMonthByMonth.FAILURE:
    case syncMonthByMonth.FAILURE:
      draft.error = payload;
      draft.loading = true;
      break;
    case retrieveMonthByMonth.FULFILL:
    case syncMonthByMonth.FULFILL:
      draft.loading = false;
      break;
  }
}, initialState);
