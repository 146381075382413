import { Action, combineReducers, EmptyObject } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import { persistReducer } from 'redux-persist';
import { FormStateMap, reducer as formReducer } from 'redux-form';
import nav, { NavState } from '@modules/nav/reducer';
import auth, { AuthState } from '@modules/auth/reducer';
import ratesUser, { RatesUserState } from '@modules/rates-user/reducer';

import { AUTH_CLEAR_SESSION } from '@modules/auth/types';
import monthByMonth, {
  MonthByMonthState,
} from '@modules/month-by-month/reducer';
import { history } from './history';
import { authPersistConfig, rootPersistConfig } from './persistor';

export type ReducerType = {
  form: FormStateMap;
  router: RouterState;
  nav: NavState;
  auth: AuthState;
  ratesUser: RatesUserState;
  monthByMonth: MonthByMonthState;
};

const appReducer = combineReducers<ReducerType | undefined>({
  // external
  router: connectRouter(history),
  form: formReducer,

  // app
  nav,
  auth: persistReducer(authPersistConfig, auth),
  ratesUser,
  monthByMonth,
});

// Actions to empty the reducer. i.e LOGOUT
const actions: Set<string> = new Set([AUTH_CLEAR_SESSION]);

const rootReducer = (state: ReducerType | undefined, action: Action) => {
  if (actions.has(action.type)) {
    return appReducer({} as EmptyObject & ReducerType, action);
  }

  return appReducer(state, action);
};

export default persistReducer<ReducerType>(rootPersistConfig, rootReducer);
export { appReducer };
