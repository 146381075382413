/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-param-reassign */
// @ts-nocheck
import {
  createMatchSelector,
  LOCATION_CHANGE,
  push,
  RouterLocation,
} from 'connected-react-router';
import { put, select, takeEvery, StrictEffect } from 'redux-saga/effects';
import { routes } from '@routes/config';
import { match, matchPath } from 'react-router-dom';
import queryString from 'qs';
import RoutesType from '@routes/interface';
import { Action } from 'redux-actions';
import { getCurrentLocation, getFilters } from './selectors';
import { setNavError, setUrlFilters, updateFiltersFromUrl } from './actions';

export const flatRoutes = (data: RoutesType[]): RoutesType[] =>
  data.reduce((previous: RoutesType[], element) => {
    const innerRoutes = element?.routes;
    previous.push(element);
    innerRoutes && previous.push(...flatRoutes(innerRoutes));
    return previous;
  }, []);

export function* locationChangeSaga(): Generator<StrictEffect> {
  try {
    const paths = flatRoutes(routes);
    const currentLocation = yield select(getCurrentLocation);
    const matches = paths
      .filter((path) => path.path && path.path !== '*')
      .find((path) =>
        matchPath((currentLocation as RouterLocation<unknown>).pathname, {
          path: path.path,
          exact: true,
          strict: false,
        }),
      );

    if (!matches) return;

    const initialSearch = queryString.parse(
      (currentLocation as RouterLocation<unknown>).search,
      {
        ignoreQueryPrefix: true,
      },
    );

    const filters = matches.defaultFilters;
    const search = { ...filters, ...initialSearch };

    yield put(updateFiltersFromUrl(search));

    const matchSelector = createMatchSelector(matches.path);

    const parametersMatch = yield select(matchSelector);
    if (matches.actions) {
      for (const action of matches.actions) {
        yield put(action({ ...(parametersMatch as match)?.params, ...search }));
      }
    }
  } catch (error) {
    yield put(setNavError(error));
  }
}

export function* setUrlFiltersSaga({
  payload,
}: Action<Record<string, unknown>>): Generator<StrictEffect> {
  try {
    const currentLocation = yield select(getCurrentLocation);
    const filters = yield select(getFilters);
    const nextFilters = { ...filters, ...payload };

    const qs = queryString.stringify(nextFilters);
    let path = currentLocation.pathname;

    if (qs) {
      path += `?${qs}`;
    }
    yield put(push(path));
  } catch (error) {
    yield put(setNavError(error));
  }
}

export default function* navWatch(): Generator<StrictEffect> {
  yield takeEvery(LOCATION_CHANGE, locationChangeSaga);
  yield takeEvery(setUrlFilters.toString(), setUrlFiltersSaga);
}
