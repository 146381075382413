/* eslint-disable unicorn/no-keyword-prefix */
/* eslint-disable redux-saga/yield-effects */
// @ts-nocheck
import {
  all,
  call,
  put,
  select,
  StrictEffect,
  takeLatest,
} from 'redux-saga/effects';
import _ from 'lodash';
import { SDK } from '@utils/sdk';
import format from 'date-fns/format';
import { getFilters } from '@modules/nav/selectors';
import { getRates } from '@modules/rates-user/selectors';
import { retrieveRates, saveRates } from './actions';

export function* retriveRatesUser(): Generator<StrictEffect> {
  try {
    yield put(retrieveRates.request());

    const filters = yield select(getFilters);
    let newDate = filters.date;
    if (typeof newDate === 'string') {
      const [year, month] = filters.date.split('-');
      newDate = new Date(year, month - 1);
    }
    const id = format(newDate, 'yyyy-MM');
    const {
      data: { users },
    } = yield call(SDK.get, `rates/${id}`);

    yield put(retrieveRates.success(users));
  } catch (error) {
    yield put(retrieveRates.failure(error));
  } finally {
    yield put(retrieveRates.fulfill());
  }
}

export function* updateRatesUser({
  payload: { values },
}): Generator<StrictEffect> {
  try {
    yield put(saveRates.request());

    const filters = yield select(getFilters);
    const currentMembers = yield select(getRates);
    const [year, month] = filters.date.split('-');
    const id = format(new Date(year, month - 1), 'yyyy-MM');

    const changes = _.differenceWith(
      _.toPairs(values.members),
      _.toPairs(currentMembers),
      _.isEqual,
    );

    const updateMembers = changes.map((change) => {
      return call(SDK.put, `rates/${id}/user/${change[1].id}`, {
        rate: change[1].rate,
      });
    });

    yield all(updateMembers);

    const {
      data: { users },
    } = yield call(SDK.get, `rates/${id}`);

    yield put(saveRates.success(users));
  } catch (error) {
    yield put(saveRates.failure(error));
  } finally {
    yield put(saveRates.fulfill());
  }
}

export default function* ratesUserWatch(): Generator<StrictEffect> {
  yield takeLatest(retrieveRates.TRIGGER, retriveRatesUser);
  yield takeLatest(saveRates.TRIGGER, updateRatesUser);
}
